<template lang="">
  <div class="">

    <card class="custom-card">
      <b-row align-v="center" slot="header" >
        <b-col cols="8">
          <h2 class="mb-0">แก้ไขโปรไฟล์ </h2>
        </b-col>
      </b-row>

      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <b-form @submit.prevent="handleSubmit(editHandler)">
          <h6 class="heading-small text-muted mb-4">ข้อมูลผู้ใช้</h6>

          <div class="pl-lg-4">
            <div class="row">
              <div class="col-lg-6">
                <div class="mb-2">
                  <label class="form-label label-required">Username</label>
                  <base-input name="username"
                              :rules="{required: true, min: 6}"
                              placeholder="Username"
                              v-model="profile.name" disabled>
                  </base-input>
                </div>
                <div class="mb-2">
                  <label class="form-label label-required">ชื่อ-นามสกุล</label>
                  <base-input name="fullname"
                              :rules="{required: true}"
                              placeholder="ชื่อ-นามสกุล"
                              v-model="profile.fullname">
                  </base-input>
                </div>
                <div class="mb-2">
                  <label class="form-label label-required">อีเมล</label>
                  <base-input name="email" type="email"
                              :rules="{required: true}"
                              placeholder="อีเมล"
                              v-model="profile.email">
                  </base-input>
                </div>
                <div class="mb-2">
                  <label class="form-label label-required">เบอร์โทรศัพท์</label>
                  <base-input name="moblie" v-number-only
                              placeholder="เบอร์โทรศัพท์"
                              v-model="profile.mobile">
                  </base-input>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-2">
                  <label class="form-label label-required">ไลน์</label>
                  <base-input name="line"
                              :rules="{required: true}"
                              placeholder="ไลน์"
                              v-model="profile.line">
                  </base-input>
                </div>
                <div class="mb-3">
                  <label class="form-label label-required">วันเกิด</label>
                  <date-picker :format="'DD/MM/YYYY'" v-model="profile.dob" class="w-100" input-class="form-control" placeholder="วันเกิด" :append-to-body="false" :popup-style="{left: 0}"></date-picker>
                  <base-input name="dob" :rules="{required: true}" v-model="dob" class="custom-input-valid-datepicker"></base-input>
                </div>
                <!-- <div class="mb-2">
                  <label class="form-label">Password</label>
                  <base-input type="password" name="password"
                              :rules="{min: 8}"
                              placeholder="Password"
                              v-model="profile.password">
                  </base-input>
                </div> -->
                <div class="mb-2">
                  <label class="form-label">กลุ่มผู้ใช้</label>
                  <input  type="text"
                          class="form-control"
                          v-model="profile.group_name"
                          disabled >
                </div>
              </div>
              </div>
          </div>
          <hr class="my-4">

          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">ข้อมูลการติดต่อ</h6>

          <div class="pl-lg-4">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-2">
                  <label class="form-label">ที่อยู่</label>
                  <textarea class="form-control" rows="4" v-model="profile.address"></textarea>
                </div>
              </div>
            </div>
          </div>

          <button ref="editProfileForm" style="display:none;">Submit</button>
        </b-form>
      </validation-observer>
    </card>
    <div class="text-right margin_top_20">
      <button type="button" class="btn btn-primary style_btn" @click="onOutsideSubmit()"><i class="fa fa-check"></i> บันทึก</button>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import DateUtils from '../../../util/dateUtils';
import SessionStorageUtils from '../../../util/sessionStorageUtils';
export default {
  name: 'profile-view-form-profile',
  data () {
    return {
      dob: ''
    }
  },
  props: [
    'profile', 'groups', 'callback'
  ],
  methods: {
    onOutsideSubmit(){
      this.$refs.editProfileForm.click();
    },
    async editHandler () {
      const params = JSON.parse(JSON.stringify(this.profile));
      params.dobData = DateUtils.dateFormat(this.profile.dob, "YYYY-MM-DD");
      const result = await this.HttpServices.putData(`/ab_users/${this.profile.id}`, params);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, async ()=>{
          const refreshToken = await this.HttpServices.postData(`/auth/refreshToken`, {id: this.profile.id, username: this.profile.name});
          SessionStorageUtils.setUser(refreshToken.data);
          window.location.reload();
        });
        this.$emit("callback", "");
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    }
  },
  mounted () {},
  directives: {
    datepicker: {
        bind: function (el, binding, vnode) {
          var value = binding.value;
          $(function(){
              var dateOption = {
                  autoclose: true,
                  format: 'dd/mm/yyyy',
              };
              $(el).datepicker(dateOption);
              if(value){
                $(el).datepicker("update", new Date(value));
              }
          });
        },
        update: function (newValue) {
        },
    },
  },
  watch: {
    'profile.dob': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.dob = ""+newVal;
        }else{
          this.dob = "";
        }
      }
    }
  }
}
</script>
<style scoped>
  input[type=file] {
    left: 0;
    top: 0;
    opacity: 0;
    position: absolute;
    font-size: 117px;
  }
</style>
