<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="card">
      <div class="card-body bg-white">
        <b-row>
          <b-col xl="4" class="order-xl-2 mb-5">
            <card-profile :profile="profile" :funcs="funcs" @callback="getProfile()" />
          </b-col>
          <b-col xl="8" class="order-xl-1">
            <form-profile :profile="profile" :groups="groups" @callback="getProfile()" />
          </b-col>
        </b-row>
      </div>
    </div>

  </div>
</template>
<script>
  import formProfile from './view/form.profile';
  import mock from './mock/profile';
  import cardProfile from './view/card.profile';
  import * as scripts from '../../util/scripts';
  import SessionStorageUtils from '../../util/sessionStorageUtils';
  import Config from '../../util/config';

  export default {
    name: 'profile-index',
    data () {
      return {
        files: [],
        profile: {
          id: '',
          name: '',
          fullname: '',
          email: '',
          moblie: '',
          line: '',
          dob: '',
          avatar: '',
          password: '',
          group_id: '',
          group_name: '',  // prop นี้ไม่ได้มีใน class diagram and ERD
          created: '',
          updated: '',
          last_login: '',
          contact: '',
          addredd: '',
          status: '',
          setting: ''
        },
        groups: [],
        mock: mock,
        funcs: scripts.funcs
      }
    },
    methods: {
      async getGroups () {
        this.groups = await this.HttpServices.getMasterData("/master/getAbGroups");
      },
      async getProfile () {
        const user = await SessionStorageUtils.decodeUser();
        const result = await this.HttpServices.getData(`/ab_users/profile/${user.id}`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          this.profile = data;
          if(this.profile.dob){
            this.profile.dob = new Date(this.profile.dob);
          }
          if(this.profile.avatar){
            this.profile.avatar = JSON.parse(this.profile.avatar);
            this.profile.avatar_encode = this.profile.avatar.url;
          }
        }else{
          this.AlertUtils.alert("warning", result.status.message);
        }
      },
      getRemoveValueAvatar (empty) {
        this.files = empty
      }
    },
    components: {
      formProfile,
      cardProfile
    },
    async mounted () {
      await this.getGroups()
      await this.getProfile()
    }
  }
</script>
