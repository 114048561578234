export default {
  profile: {
    id: 1,
    name: 'Leanne',
    fullname: 'Leanne',
    email: 'Sincere@april.biz',
    moblie: '1-770-736-8031 x56442',
    line: 'Bret',
    dob: '10/05/2021',
    avatar: '',
    password: '',
    group_id: '1',
    group_name: 'Administrator', // prop นี้ไม่ได้มีใน class diagram and ERD
    created: '10/05/2021',
    updated: '10/05/2021',
    last_login: '10/05/2021',
    contact: '',
    addredd: '',
    status: '',
    setting: ''
  },
  groups: [{
    id: 1,
    code: '01',
    name: 'Administrator'
  }, {
    id: 2,
    code: '02',
    name: 'Manager'
  }, {
    id: 3,
    code: '03',
    name: 'User'
  }]
}
