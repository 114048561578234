<template>
  <b-modal id="dialog_common_upload" title="Upload Image">
    <template #modal-title>
      <h3><i class="fa fa-upload"></i> Upload Image</h3>
    </template>
    <div>
      <input type="file" id="fileUpload" accept="image/*" style="display: none" @change="uploadFileChangeHandler"/>
      <div class="card custom-thumbnail">
        <div class="card-body">
          <img :src="displayImg?displayImg:'/img/noimage.png'"/>
        </div>
      </div>
      <div class="text-center mt-5">
        <b-button variant="primary" @click="uploadFileHandler()"><i class="fa fa-upload"></i> Upload</b-button>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="primary" @click="uploadHandler()"><i class="fa fa-check"></i> ตกลง</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
  import $ from 'jquery';
  import Config from '../../util/config';
  import StringUtils from '../../util/StringUtils';
  export default {
    name: 'common-view-dialog-upload',
    props: [
      'uploadUrl', 'fileImg', 'callback', 'fileCategory'
    ],
    data() {
      return {
        file: null,
        displayImg: ''
      }
    },
    methods: {
      uploadFileHandler(){
          $("#fileUpload").trigger("click");
      },
      uploadFileChangeHandler (event) {
        let img = event.target.files
        if (img.length > 0) {
          const reader = new FileReader();
          const vm = this;
          reader.onload = function (e) {
            vm.displayImg = e.target.result;
          };
          this.file = img[0];
          reader.readAsDataURL(img[0]);
        } else {
          this.displayImg = null;
          this.file = null;
        }
      },
      async uploadHandler () {
        this.fileCategory= 'profile';
        if (!this.file) {
          this.AlertUtils.alert('warning', `กรุณาเลือกไฟล์`);
          this.$bvModal.hide('dialog_common_upload');
          return;
        }
        const s3Auth = await this.HttpServices.authenS3();
        if(s3Auth._info){
          const accessToken = s3Auth._info.secret.accessToken;
          let params = new FormData();
          params.append("file", this.file);
          params.append("title", this.file.name);
          params.append("file_category", this.fileCategory);
          const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
          if(resultMedia.media&&resultMedia.media.length>0){
            const media = resultMedia.media[0];
            const mediaUpload = {
              id: media.id,
              original_name: media.original_name,
              mime_type: media.mime_type,
              size: media.size,
              url: media.url
            };

            params = new FormData();
            const thumbFile = await StringUtils.resizeBlobToFile({file: this.file, maxSize: 200});
            params.append("file", thumbFile);
            params.append("title", thumbFile.name);
            params.append("file_category", this.fileCategory);
            let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
            if(resultThumb.media&&resultThumb.media.length>0){
              const mediaThumb = resultThumb.media[0];
              mediaUpload.id_t = mediaThumb.id;
              mediaUpload.original_name_t = mediaThumb.original_name;
              mediaUpload.mime_type_t = mediaThumb.mime_type;
              mediaUpload.size_t = mediaThumb.size;
              mediaUpload.url_t = mediaThumb.url;
            }
            params = {media: JSON.stringify(mediaUpload)};
            const result = await this.HttpServices.postData(this.uploadUrl, params);
            if(result&&result.status.code=="200"){
              const data = result.data;
              if(data&&data.older){
                try{
                  const older = JSON.parse(data.older);
                  this.HttpServices.deleteDataS3(older.id, accessToken);
                  if(older.id_t){
                    this.HttpServices.deleteDataS3(older.id_t, accessToken);
                  }
                }catch(e){}
              }
              this.AlertUtils.alert('success', `อัพโหลดสำเร็จ`);
              this.$bvModal.hide('dialog_common_upload');
              this.$emit("callback", "");
            }else{
              this.AlertUtils.alert('warning', result.status.message);
            }
          }
        }
      },
      close () {
        this.$bvModal.hide('dialog_common_upload');
      }
    },
    watch: {
      fileImg: {
        immediate: true,
        handler (newVal, oldVal) {
          this.displayImg = newVal?`${newVal}`:'';
        }
      }
    },
  }
</script>
<style>

  .custom-thumbnail{
    width: 40%;
    height: auto;
    margin: 0px auto;
  }
  .custom-thumbnail img{
    width: 100%;
    height: auto;
  }
</style>
