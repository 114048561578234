<template>
  <div>

    <b-card-header class="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <a href="#">
        <b-img  :src="funcs.isImgEmpty(profile.avatar_encode)" rounded="circle"
                style="max-width: 180px;" />
      </a>
      <div class="text-center margin_top_20">
        <b-button variant="primary" @click="uploadImageHandler()"><i class="fa fa-upload"></i> แก้ไขรูป</b-button>
      </div>
    </b-card-header>

    <b-card-body class="pt-0">
      <div>
        <hr class="my-4">
        <div class="row">
          <div class="col-sm-6">
            <span class="description">
              <strong style="color: #adb5bd;">วันที่เริ่มต้น</strong>
            </span>
          </div>
          <div class="col-sm-6"><strong>{{profile.created}}</strong></div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <span class="description">
              <strong style="color: #adb5bd;">วันที่แก้ไขล่าสุด</strong>
            </span>
          </div>
          <div class="col-sm-6"><strong>{{profile.updated}}</strong></div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <span class="description">
              <strong style="color: #adb5bd;">วันที่ล็อคอินที่แล้ว</strong>
            </span>
          </div>
          <div class="col-sm-6"><strong>{{profile.last_login}}</strong></div>
        </div>
      </div>
    </b-card-body>
    <div>
      <dialog-common-upload :uploadUrl="uploadUrl" :fileImg="fileImg" :fileCategory="fileCategory" @callback="getProfile"/>
    </div>
  </div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import dialogCommonUpload from '../../../components/Common/dialog.upload.vue';
import Config from '../../../util/config';
import SessionStorageUtils from '../../../util/sessionStorageUtils';
export default {
  components: { BaseButton },
  name: 'profile-view-card-profile',
  props: [
    'profile', 'funcs', 'callback'
  ],
  data() {
    return {
      uploadUrl: '',
      fileImg: '',
      fileCategory: 'profile'
    }
  },
  methods: {
    async getProfile(){
      const user = await SessionStorageUtils.decodeUser();
      const refreshToken = await this.HttpServices.postData(`/auth/refreshToken`, {id: user.id, username: user.name});
      SessionStorageUtils.setUser(refreshToken.data);
      window.location.reload();
      this.$emit("callback", "");
    },
    uploadImageHandler(){
      this.uploadUrl = `/ab_users/upload/${this.profile.id}`;
      this.fileImg = this.profile.avatar_encode;//.replace(`${Config.apiUrl}/resources/image/`, "");
      this.$bvModal.show('dialog_common_upload');
    }
  },
  components: {dialogCommonUpload}
};
</script>
<style></style>
